import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

export default function RemoveApproval(props) {

    const [show, setShow] = useState(false);

    useEffect(()=> {
        if (props.row.values.endDisplay.length > 2) {
            setShow(true);
        }
    }, [props.row]); 
    
    const Parse = require('parse');

    const disapprove = (e) => {
        e.preventDefault();

        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.get(props.row.values.idDisplay)
        .then(function(timesheet) {
            timesheet.set('approved', false);
            timesheet.set('approvedBy', null);
            timesheet.save()
            .then((res)=>{
                const dataCopy = props.timesData;
                var thisSheet = dataCopy[props.row.index];
                thisSheet.approvedDisplay = '';
                thisSheet.approvedByDisplay = '';
                thisSheet.isEditableDisplay = false;
                dataCopy[props.row.index] = thisSheet;
                props.setTimesData(dataCopy);
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    return <>
        {(show) && (
            <Button variant='outline-danger' onClick={disapprove} className='ml-3'>
                <i className="fas fa-times"></i>
            </Button>
        )}
    </>
}
