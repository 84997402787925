// Login form and handler

// To do:
// Add forgot password link and workflow
// Handle distinction between and redirects for staff/client logins

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Main from "./../Main";
import Logo from './../riskcon-logo.png';
import Cookies from 'js-cookie';
import { Form, Button } from 'react-bootstrap';
// import axios from 'axios';

// import ClientMain from "./client-view/ClientMain";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState();
    const [rememberMe, setRememberMe] = useState(true);

    const Parse = require('parse');

    Parse.initialize(
        process.env.REACT_APP_PARSE_APP_ID,
        process.env.REACT_APP_PARSE_JS_KEY
        );
    
    Parse.serverURL = process.env.REACT_APP_PARSE_API_URL;

    // useEffect(() => {
    //     const rememberMe = Cookies.get('rememberMe');
    //     const email = rememberMe ? Cookies.get('email') :'';
    //     if(rememberMe) {
    //         setEmail(email);
    //     }
    //   }, []);

    // const checkedHandleChange = (e) => {
    //     setRememberMe(e.target.checked);
    // }

    const emailHandleChange = (e) => {
        document.getElementById('form-feedback').innerText = '';
        setEmail(e.target.value);
    }

    const passwordHandleChange = (e) => {
        document.getElementById('form-feedback').innerText = '';
        setPassword(e.target.value);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        Parse.User.logIn(email,password)
        .then((result) => {
            setUser(result.data);
            // Cookies.set('rememberMe', rememberMe, { expires: 30 });
            // Cookies.set('email', rememberMe ? email : '', { expires: 30 });
            Cookies.set('user', user);
            document.getElementById('form-feedback').innerText = ''; 
            Parse.Session.current()
            .then(res => {
                var SessionToken = res.getSessionToken();
                Cookies.set('sessionToken', SessionToken);
            });

            // const url = process.env.REACT_APP_SLACK_WEBHOOK_URL;
            // const data = { text: 'whatup'};

            // axios.post(url, JSON.stringify(data), {
            //     withCredentials: false,
            //     transformRequest: [
            //         (data, headers) => {
            //             return data;
            //         },
            //     ],
            // });

            // const url2 = 'https://api.telegram.org/bot' + process.env.REACT_APP_TELEGRAM_BOT_TOKEN + '/sendMessage';
            // const data2 = { 
            //     chat_id: result.get('telegramBotChatId'), 
            //     text: 'whatup',
            // };

            // axios.post(url2, data2);

            // if (result.id === 'wl1BT7ihRT') {
            //     ReactDOM.render(
            //         <React.StrictMode>
            //             <ClientMain />
            //         </React.StrictMode>,
            //         document.getElementById('root')
            //     );
            // } else {
                ReactDOM.render(
                    // <React.StrictMode>
                        <Main />
                    // </React.StrictMode>,
                    ,
                    document.getElementById('root')
                );
            // }
              
        }).catch((error) => {
            document.getElementById('form-feedback').innerText = error.message;
        });
    }
    
    return <>
        <div className="flex-container box-shadow"> 
            <img src={Logo} className="logo mb-4" alt="Riskcon Logo" />
            <div className="item">
                <Form onSubmit={handleSubmit}>
                    <Form.Control
                        className="mb-2 text-input-form"
                        type="email"
                        value={email}
                        placeholder='Email Address'
                        onChange={emailHandleChange}
                        required
                        autoFocus
                    />
                    <Form.Control
                        className="mb-2 text-input-form"
                        type="password"
                        value={password}
                        placeholder='Password'
                        onChange={passwordHandleChange}
                        required
                    />
                    {/* <Form.Group className="space-between mb-4" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" checked={rememberMe} label="Remember Me" onChange={checkedHandleChange} /> */}
                        {/* <a href="/forgot-password">Forgot Password?</a> */}
                    {/* </Form.Group> */}
                    <Button className="primary login-button" type="submit">
                        Login
                    </Button>
                    <div id='form-feedback' className='form-fail'>
                        
                    </div>
                </Form>
                <br />
                {/* <p>Contact: <a href="mailto:portal@loteconsulting.com">portal@loteconsulting.com</a></p> */}
            </div>
        </div>
    </> 
}
