import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Table, Badge, Breadcrumb, Tooltip, OverlayTrigger, Stack, Form } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/en-au';
import NumberFormat from 'react-number-format';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';

// Components
// import UpdateProjectStatus from './UpdateProjectStatus';
// import EditCustomerAction from './EditCustomerAction';
// import ClearCustomerAction from './ClearCustomerAction';
import AddDocument from './AddDocument';
import AddInvoice from './AddInvoice';
import EditProject from './EditProject';
// import EditInvoice from './EditInvoice';
// import EditClassifications from './EditClassifications';
import IssueInvoice from './IssueInvoice';
import PaidStatus from './PaidStatus';
import EditProjectClient from './EditProjectClient';


// CSS
import './ProjectView.css';

export default function ProjectView({ match }) {

    // Project Info
    const [project, setProject] = useState('')
    const [name, setName] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    const [projectId, setProjectId] = useState('');
    // const [status, setStatus] = useState();
    // const [action, setAction] = useState('');
    // const [history, setHistory] = useState([]);
    const [actionHistory, setActionHistory] = useState([]);

    // Organisation
    const [organisationName, setOrganisationName] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientId, setClientId] = useState('');

    // Manager
    const [managerName, setManagerName] = useState('');
    const [secondName, setSecondName] = useState('');
    // const [managerPhone, setManagerPhone] = useState('');
    // const [managerEmail, setManagerEmail] = useState('');

    // Adddres
    const [address, setAddress] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postCode, setPostCode] = useState('');
    const [country, setCountry] = useState('Australia');

    // Project Classification
    const [stage, setStage] = useState();
    const [sector, setSector] = useState([]);
    const [buildingClass, setBuildingClass] = useState();
    const [service, setService] = useState([]);
    const [projectValue, setProjectValue] = useState();
    const [fee, setFee] = useState();
    const [tags, setTags] = useState([]);
    const [nonCompliance, setNonCompliance] = useState([]);
    const [over25mHeight, setOver25mHeight] = useState();
    const [featured, setFeatured] = useState();
    const [driveLink, setDriveLink] = useState('');
    const [photosLink, setPhotosLink] = useState('');
    const [internalNote, setInternalNote] = useState('');
    const [featureDescription, setFeatureDescription] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const [taskArray, setTaskArray] = useState([]);
    const [invoiceArray, setInvoiceArray] = useState([]);
    const [documentArray, setDocumentArray] = useState([]);

    const [timesheetArray, setTimesheetArray] = useState([]);
    const [isTimesheetLoaded, setIsTimesheetLoaded] = useState(false);
    // const [individualFilter, setIndividualFilter] = useState(false);

    const [projectStatus, setProjectStatus ] = useState('');

    const [isLoaded, setIsLoaded] = useState(false);
    const [pmOptions, setPmOptions] = useState();
    const [isPmOptionsLoaded, setIsPmOptionsLoaded] = useState(false);

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [cardMode, setCardMode] = useState('light');

    let history = useHistory();

    const [userIsAdmin, setUserIsAdmin] = useState(false);

    const queryAdmin = () => {
        // if (currentUser.id === 'WiGacaXnel' || currentUser.id === 'BKD0LlA2EC' ) {
        if (currentUser.id === 'WiGacaXnel' || currentUser.id === 'BKD0LlA2EC' || currentUser.id === 'NS7ecaLkAU') {
            setUserIsAdmin(true);
        }
    }

    useEffect(() => {
        queryAdmin(); 
    },[])

    useEffect(() => {
        if (currentUser.attributes.darkMode) {
            setCardMode('dark');
        } else {
            setCardMode('light');
        }
    },[currentUser.attributes.darkMode])

    useEffect(() => {
        document.title = match.params.id + " – Dashboard";
        if (!isPmOptionsLoaded) {
            getPmOptions();
        }
        queryItem();
    },[]);

    const getPmOptions = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','projectManagers').first().then(staff => { 
            const users = staff.getUsers().query();
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                const currentUser = Parse.User.current();
                for (let i = 0; i < userQueryRes.length; i++) {
                    if (currentUser.id === userQueryRes[i].id) {
                        var thisUser = userQueryRes.splice(i,1);
                        userQueryRes.unshift(thisUser[0]); 
                        break;
                    }
                }
                const options = userQueryRes.map(user => ({
                    "value" :user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setPmOptions(options);
                setIsPmOptionsLoaded(true);
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const queryItem = () => {
        const Project = Parse.Object.extend('Project')
        const query = new Parse.Query(Project);
        query.equalTo('projectId', parseInt(match.params.id, 10));
        query.includeAll();
        query.first()
        .then(function(res) {
            setProject(res);
            getTimesheetArray(res.attributes.projectId);
            getTaskArray(res);
            getInvoiceArray(res);
            getDocumentArray(res);

            const orgNameString = res.get('organisationId').get('name');

            // Set Contact
            res.relation('users').query().first().then((res)=>{
                const getAttribute = (attribute) => {
                    return res.get(attribute);
                }
                const usersNameString = getAttribute('firstName') + ' ' + getAttribute('lastName');
                setClientName(usersNameString);
                if (usersNameString !== orgNameString) {
                    setOrganisationName(orgNameString);
                } else {
                    setOrganisationName('')
                }
                setClientPhone(getAttribute('phone'));
                setClientEmail(getAttribute('username'));
                setClientId(res.id);
            })

            // Set Classification
            setStage(res.get('stage'));
            setSector(res.get('sector'));
            setBuildingClass(res.get('buildingClass'));
            setService(res.get('service'));
            setProjectValue(res.get('value'));
            setFee(res.get('fee'));
            setTags(res.get('tags'));
            setNonCompliance(res.get('nonCompliance'));
            setProjectStatus(res.get('status'));
            setOver25mHeight(res.get('over25mHeight'));
            setFeatured(res.get('featured'));
            setFeatureDescription(res.get('featureDescription'));
            setFeatureImage(res.get('featureImage'));
            setDriveLink(res.get('googleDrive'));
            setPhotosLink(res.get('googlePhotos'));
            setInternalNote(res.get('internalNotes'));

            queryManager(res);
            setValue(res);
        }).catch((error) => {
            history.push('/page-not-found');
            // alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const queryManager = (result) => {
        const User = Parse.Object.extend('User');
        const query = new Parse.Query(User);
        query.get(result.attributes.managerId.id)
        .then(function(res) {
            setManagerName(res.attributes.firstName + ' ' + res.attributes.lastName);
            // setManagerPhone(res.attributes.phone);
            // setManagerEmail(res.attributes.username);
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
        const query2 = new Parse.Query(User);
        query2.get(result.attributes.secondId.id)
        .then(function(res) {
            setSecondName(res.attributes.firstName + ' ' + res.attributes.lastName);
            // setManagerPhone(res.attributes.phone);
            // setManagerEmail(res.attributes.username);
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const setValue = (res) => {
        const a = res.attributes;
        setProjectId(a.projectId);
        setUpdatedAt(a.updatedAt.toString());
        setName(a.name);

        // return if statement as address will be mandatory
        // if(a.address) {
        //     setAddress(a.address.street + ' ' + a.address.city + ' ' + a.address.state + ' ' + a.address.zip + ' ' + a.address.country);
        //     setStreet(a.address.street);
        //     setCity(a.address.city);
        //     setState(a.address.state);
        //     setPostCode(a.address.zip);
        //     setCountry(a.address.country);
        // }

        // if(a.action) {
        //     setAction(a.action);
        // } else {
        //     setAction('No action required');
        // }
        // setActionHistory(a.actionHistory);
        // getStatusHistory(a);
    }

    // const getStatusHistory = (a) => {
    //     setHistory(a.statusHistory);
    //     const currentStatus = a.statusHistory[a.statusHistory.length-1];
    //     if(currentStatus.name) {
    //         setStatus(currentStatus.stage + ' – ' + currentStatus.name);
    //     } else {
    //         setStatus(currentStatus.stage);
    //     }
    // }

    const getTimesheetArray = (projectId) => {

        const Timesheet = Parse.Object.extend('Timesheet');
        const timesheet = new Parse.Query(Timesheet);

        timesheet.equalTo('projects', projectId.toString());
        timesheet.find()
        .then((results) => {
            setTimesheetArray(results);
            setIsTimesheetLoaded(true);
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const getTaskArray = (project) => {

        const Task = Parse.Object.extend('Task');
        const task = new Parse.Query(Task);

        task.equalTo('project', project);
        task.ascending('deadline');
        task.find()
        .then((results) => {
            setTaskArray(results);
            setIsLoaded(true);
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const getInvoiceArray = (project) => {

        const Invoice = Parse.Object.extend('Invoice');
        const invoice = new Parse.Query(Invoice);

        invoice.equalTo('projectId', project);
        invoice.equalTo('archive', false);
        invoice.find()
        .then((results) => {
            setInvoiceArray(results);
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const getDocumentArray = (project) => {

        const Document = Parse.Object.extend('Document');
        const document = new Parse.Query(Document);
        document.include("reviewedBy");
        document.equalTo('projectId', project);
        document.equalTo('archive', false);
        document.ascending('reviewedAt');
        document.find()
        .then((results) => {
            setDocumentArray(results);
            var documentArray = [];
            for (var i in results) {
                var obj = results[i];
                var reviewedByName = obj.get('document');

                documentArray.push({
                    reviewedBy: reviewedByName
                });
            }
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const TaskRow = () => {
        if (taskArray.length > 0) {

            const taskTableRow = taskArray.map((item, i) =>{
                var completeString = '';
                var complete = item.get('complete');
                if (complete) {
                    completeString = moment(item.get('completedOn')).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A' 
                    });
                }
                return (
                    <tr key={i} id={'task-row-'+i+'-'+item.id}>
                        <td>{item.get('assignedTo').attributes.firstName + ' ' + item.get('assignedTo').attributes.lastName}</td>
                        <td>{item.get('action')}</td>
                        <td>{moment(item.get('deadline')).locale('en-au').calendar({
                            // sameDay: '[Today]',
                            // nextDay: '[Tomorrow]',
                            nextWeek: 'ddd DD MMM h:mm A',
                            // lastDay: '[Yesterday]',
                            lastWeek: 'ddd DD MMM h:mm A',
                            sameElse: 'ddd DD MMM h:mm A' 
                        })}</td>
                        <td>{completeString}</td>
                    </tr>
                )
            });

            return taskTableRow;
        }  else if (!isLoaded) {
            return <tr><td colSpan={4} style={{textAlign:'center', color:'var(--text)'}}><em>Loading tasks...</em></td></tr>
        } else {
            return <tr><td colSpan='4' style={{textAlign: 'center', color:'var(--text)'}}>No tasks</td></tr>
        }
    }

    const InvoiceRow = () => {
        if (invoiceArray.length > 0) {

            const invoiceTableRow = invoiceArray.map((item, i) =>{       
                let dueBy = item.get('dueBy')
                if (dueBy) {
                    dueBy = moment(item.get('dueBy')).locale('en-au').format('LL');
                }
                let invoiceNo = false;
                if (item.get('invoiceNo')) {
                    invoiceNo = true;
                }
                   
                return (
                    <tr key={i} id={'invoice-row-'+i+'-'+item.invoiceId}>
                        <td>{item.get('name')}</td>
                        <td>{item.get('feePercent')}</td>
                        <td>
                            <NumberFormat
                                id={'-'}
                                value={item.get('value')}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'$'}
                            />
                        </td>
                        <td>
                            <IssueInvoice
                                id={'issue-invoice-'+i}
                                project={project}
                                invoice={item}
                                action={actionHistory}
                                projectId={projectId}
                                title={item.get('name')}
                            />
                            {item.get('invoiceNo')}
                        </td>
                        <td>{dueBy}</td>
                        <td>
                            <DownloadRevision
                                file={item.get('file')}
                            />
                        </td>
                        <td>
                            <PaidStatus 
                                id={item.id}
                                project={project}
                                paid={item.get('paid')}
                                paymentDate={item.get('paymentDate')}
                                hasInvoice={invoiceNo}
                                dueBy={item.get('dueBy')}
                            />
                        </td>
                        <td style={{textAlign:'center'}}>
                            {/* <EditInvoice 
                                invoice={item}
                                name={item.get('name')}
                                fee={item.get('feePercent')}
                                amount={item.get('value')}
                                invoiceNo={item.get('invoiceNo')}
                                dueBy={item.get('dueBy')}
                            /> */}
                        </td>
                    </tr>
                )
            });


            return invoiceTableRow;
        }  else if (!isLoaded) {
            return <tr><td colSpan={8} style={{textAlign:'center', color:'var(--text)'}}><em>Loading invoices...</em></td></tr>
        } else {
            return <tr><td colSpan='8' style={{textAlign: 'center', color:'var(--text)'}}>No invoices</td></tr>
        }
        
    }

    const invoicePaidTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className="invoice-tool">
            <div>
                <i className="fas fa-hourglass-start" style={{color: '#0d6efd', minWidth: '20px'}}></i>
                <span>Awaiting Payment</span>
            </div>
            <div>
                <i className="fas fa-exclamation-circle" style={{color: '#FF0000', minWidth: '20px'}}></i>
                <span>Overdue Payment</span>
            </div>
            <div>
                <i className="fas fa-check-circle" style={{color: '#198754', minWidth: '20px'}}></i>
                <span>Paid Payment</span>
            </div>
        </Tooltip>
    );

    const DocumentRow = () => {
        if (documentArray.length > 0 ) {

            const documentTableRow = documentArray.map((item,i) => {
                let preparedByUser = item.get('preparedBy');
                let reviewedByUser = item.get('reviewedBy');
                let authorisedByUser = item.get('authorisedBy');
                let preparedByName = '';
                let reviewedByName = '';
                let authorisedByName = '';
                if(preparedByUser.attributes.firstName) {
                    preparedByName = preparedByUser.attributes.firstName + ' ' + preparedByUser.attributes.lastName;
                }
                if (reviewedByUser.attributes.firstName) {
                    reviewedByName = reviewedByUser.attributes.firstName + ' ' + reviewedByUser.attributes.lastName;
                }
                if (authorisedByUser.attributes.firstName) {
                    authorisedByName = authorisedByUser.attributes.firstName + ' ' + authorisedByUser.attributes.lastName;
                }
        
                return <>
                    <tr key={i}>
                        <td>{item.get('documentType')}</td>
                        <td>{preparedByName}</td>
                        <td>{reviewedByName}</td>
                        <td>{authorisedByName}</td>
                        <td>{moment(item.get('reviewedAt')).locale('en-au').format('LL')}</td>
                        <td>{item.get('revision')}</td>
                        <td>{item.get('version')}</td>
                        <td style={{textAlign: 'center'}}>
                            <DownloadRevision
                                file={item.get('file')}
                                oversize={item.get('over20Mb')}
                                cloudLink={item.get('cloudLink')}
                            />
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <AddDocument
                                project={project}
                                projectId={projectId}
                                clientId={clientId}
                                isRevision={true}
                                oldDocumentId={item.id}
                                documentType={item.get('documentType')}
                                revisionLetter={item.get('revision')}
                            />
                        </td>
                    </tr>
                </>
                
            });

            
            return documentTableRow;
        } else if (!isLoaded) {
            return <tr><td colSpan={9} style={{textAlign: 'center', color:'var(--text)'}}><em>Loading Documents...</em></td></tr>;
        } else {
            return <tr><td colSpan={9} style={{textAlign: 'center', color:'var(--text)'}}>No documents uploaded</td></tr>;
        }
    }

    // const sectorBadges = sector.map((sector, i)=> {
    //     return <Badge bg="secondary" key={i} style={{marginLeft: '.25rem'}}>{sector.label}</Badge>
    // })

    // const serviceBadges = service.map((service, i)=> {
    //     return <Badge bg="secondary" key={i} style={{marginLeft: '.25rem'}}>{service.label}</Badge>
    // })

    // const tagBadges = tags.map((tags, i)=> {
    //     return <Badge bg="secondary" key={i} style={{marginLeft: '.25rem'}}>{tags.label}</Badge>
    // })

    // const nonComplianceBadges = nonCompliance.map((nonCompliance, i)=> {
    //     return <Badge bg="secondary" key={i} style={{marginLeft: '.25rem'}}>{nonCompliance.label}</Badge>
    // })

    // const currencyFormatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    // });

    // var projectValueFormatted, loteFeeFormatted;
    // if (projectValue) {
    //     projectValueFormatted = currencyFormatter.format(projectValue);
    // } else {
    //     projectValueFormatted = 'N/A';
    // }
    
    // if (loteFee) {
    //     loteFeeFormatted = currencyFormatter.format(loteFee);
    // } else {
    //     loteFeeFormatted = 'N/A';
    // }

    // var heightFormatted, featureFormatted;
    // if (over25mHeight) {
    //     heightFormatted = 'Over 25m';        
    // } else {
    //     heightFormatted = 'Less than 25m';
    // }
    
    // if (featured) {
    //     featureFormatted = 'Featured project';
    // } else {
    //     featureFormatted = 'Not featured';
    // }

    // var featureDescriptionFormatted;
    // if (featureDescription) {
    //     featureDescriptionFormatted = featureDescription;
    // } else {
    //     featureDescriptionFormatted = 'N/A';
    // }

    // const driveLinkFormatted = (link) => {
    //     if(link) {
    //         return <><Button href={link} target='_blank'> <i className="fab fa-google-drive"/></Button></>;
    //     } else {
    //         return <span>N/A</span>;
    //     }
    // }

    // const photosLinkFormatted = (link) => {
    //     if(link) {
    //         return <><Button href={link} target='_blank'> <i className="fas fa-images"/></Button></>;
    //     } else {
    //         return <span>N/A</span>;
    //     }
    // }

    // const classificationFormatted = (classification) => {
    //     if(classification) {
    //         return <>{classification}</>;
    //     } else {
    //         return <span>N/A</span>;
    //     }
    // }

    // const classificationMultiFormatted = (classification) => {
    //     if (classification.length > 0 ) {
    //         return <>{classification}</>;
    //     } else {
    //         return <span>N/A</span>;
    //     }
    // }

    // const AddressFormatted = () => {
    //    if (address) {
    //         var mStreet;
    //         var mCity;
    //         var mState;
    //         var mPostcode;
    //         if(street) {
    //             mStreet = street + ', ';
    //         }
    //         if(city) {
    //             mCity = city + ', ';
    //         }
    //         if(state) {
    //             mState = state + ', ';
    //         }
    //         if(postCode) { 
    //             mPostcode = postCode + ', ';
    //         }
    //         return <>{mStreet}{mCity}{mState}{mPostcode}{country}</>
    //     } else {
    //         return null;
    //     }
    // }

    const TimesheetDisp = () => {
        if ((isTimesheetLoaded) && (timesheetArray.length > 0) && (isPmOptionsLoaded)) {
            var hoursTotal = 0;
            var billTotal = 0;
            var timesheetTableRow;

            // if (individualFilter) {

                timesheetTableRow = timesheetArray.map((item, i) =>{
                    var hoursString = '';
                    var rateString = '';
                    
                    var projectHours = item.get('projectHours');
                    if (projectHours) {
                        const subArr = projectHours.filter(str => str.includes(projectId));
                        if (subArr[0] !== undefined) {
                            hoursString = subArr[0].split(':')[1];
                            rateString += '$'
                            const billMe = Number(hoursString) * Number(subArr[0].split(':')[3]);
                            rateString += billMe;
                            hoursTotal += Number(hoursString);
                            billTotal += billMe;
                        }   
                    }
                    
                    return (
                        <tr key={i} id={'task-row-'+i+'-'+item.id}>
                            <td>{item.get('user').attributes.firstName + ' ' + item.get('user').attributes.lastName}</td>
                            <td>{moment(item.get('start')).locale('en-au').calendar({
                                sameDay: 'DD/MM/YYYY',
                                nextDay: 'DD/MM/YYYY',
                                nextWeek: 'DD/MM/YYYY',
                                lastDay: 'DD/MM/YYYY',
                                lastWeek: 'DD/MM/YYYY',
                                sameElse: 'DD/MM/YYYY' 
                            })}</td>
                            <td>{hoursString}</td>
                            {userIsAdmin && (
                                <td>{rateString}</td>
                            )}
                        </tr>
                    )
                });

            // } else {
          
            // }

            return <>
                <div className='mb-3'>
                    <Stack direction="horizontal" gap={3}>
                        <div>
                            Total {hoursTotal} hours
                        </div>
                        {userIsAdmin && (
                            <div>
                                Billable value: ${billTotal}
                            </div>
                        )}
                        {userIsAdmin && (
                            <div>
                                Fee: ${fee}
                            </div>
                        )}
                        {/* <div className='ms-auto'>
                            <Form.Group>
                                <Form.Check
                                    type="switch"
                                    // className='star-switch'
                                    label='Show timesheets'
                                    checked={individualFilter}
                                    onChange={(e) => setIndividualFilter(e.target.checked)}
                                />
                            </Form.Group>
                        </div> */}
                    </Stack>   
                </div>     
                <div className="project-container">
                    <Table hover variant={cardMode}>
                        <thead>
                            <tr>
                                <th>Team member</th>
                                {/* {individualFilter && ( */}
                                    <th>Date</th>
                                {/* )} */}
                                {/* {individualFilter && ( */}
                                    <th>Hours</th>
                                {/* )} */}
                                {/* {!individualFilter && (
                                    <th>Total Hours</th>
                                )}                                 */}
                                {userIsAdmin && (
                                    <th>Billable value</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {timesheetTableRow}
                        </tbody>
                    </Table>
                </div>        
            </>
        } else if (!isTimesheetLoaded) {
            return <>
                <em>Loading timesheets...</em>
            </>
        } else {
            return <>
                No timesheets for project.
            </>
        }
    }

    if (!isLoaded) {
        return <>
            <Container>
                <span style={{color:'var(--text)', fontSize:'1em'}}><em>Loading...</em></span>
            </Container>
        </>
    } else {
        return (
            <section>
                {/* <Breadcrumb className="container">
                    <Breadcrumb.Item href="/staff">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active >{projectId} – {name}</Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="project-header container">
                    <Container>
                        <div>
                            <div className='space-between'>
                                <h2>{projectId} – {name}</h2>
                                <Col className="flexbox-column" style={{color: 'white', textAlign:'right', marginRight: '1rem'}}>
                                    <label className="contact-label" style={{color: 'white', justifyContent:'right'}}>Lote Project Manager</label>
                                    <span>{managerName}</span>
                                </Col>
                                <Col className="flexbox-column" style={{color: 'white', textAlign:'right', marginRight: '1rem'}}>
                                    <label className="contact-label" style={{color: 'white', justifyContent:'right'}}>Lote Project Copilot</label>
                                    <span>{secondName}</span>
                                </Col>
                                <EditProject
                                    project={project}
                                    street={street}
                                    city={city}
                                    state={state}
                                    postCode={postCode}
                                    country={country}
                                    projectNo={projectId}
                                    name={name}
                                />
                            </div>
                            {/* <div className='space-between'>
                                <span><AddressFormatted/></span>
                            </div> */}
                        </div>
                    </Container>
                </div>
                <Container className="mb-4 white">
                    <div className="project-content">
                        <div id="quick-action">
                            {/* <Stack direction="horizontal" gap={3}>
                                <div className="bg-light border">
                                    <label className="contact-label" style={{justifyContent:'right'}}>Lote Project Manager</label>
                                    <span>{managerName}</span>
                                </div>
                                <div className="bg-light border">
                                    <label className="contact-label" style={{justifyContent:'right'}}>Lote Project Manager</label>
                                    <span>{managerName}</span>
                                </div>
                                <div className="bg-light border"></div>
                            </Stack> */}
                            {/* <Col className="flexbox-column" style={{textAlign:'right', marginRight: '1rem'}}>
                                <label className="contact-label" style={{justifyContent:'right'}}>Lote Project Manager</label>
                                <span>{managerName}</span>
                            </Col> */}
                            {/* <Col className="flexbox-column" style={{textAlign:'right', marginRight: '1rem'}}> */}
                            {/* <Col className="flexbox-column" style={{textAlign:'left'}}>
                                <label className="contact-label" style={{marginLeft: 0}}>Lote Project Manager</label>
                                <span>{managerName}</span>
                            </Col> */}
                            {/* <Col className="flexbox-column" style={{textAlign:'right', marginRight: '1rem'}}>
                                <label className="contact-label" style={{justifyContent:'right'}}>Last Updated</label>
                                <span>{updatedAt.toString().substr(0,21)}</span>
                            </Col> */}
                            {/* <UpdateProjectStatus 
                                project={project}
                                status={projectStatus}
                            /> */}
                        </div>
                        <div className="mt-2">
                            <Row>
                                <Col md={6}>
                                    <div className="section-header">
                                        <h5>Client</h5>
                                    </div>
                                    <div className="project-container">
                                        <div className="project-section">
                                            <div className="col-lg-12">
                                                <div className="project-subheader">
                                                    <i className="fas fa-hard-hat" style={{paddingRight:'.5rem'}}/>
                                                    <label>{organisationName}</label>
                                                    {/* <span style={{float:'right', paddingRight:'.5rem'}}>Visibility = Hidden</span> */}
                                                </div>
                                                <Row className="mt-3">
                                                    <Col md={10}>
                                                        <div className="contact-row">
                                                            <span className="contact-label col-md-3">Contact Name</span>
                                                            <span>{clientName}</span>
                                                        </div>
                                                        <div className="contact-row">
                                                            <span className="contact-label col-md-3">Contact Phone</span>
                                                            <span>{clientPhone}</span>
                                                        </div>
                                                        <div className="contact-row">
                                                            <span className="contact-label col-md-3">Contact Email</span>
                                                            <span>{clientEmail}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <EditProjectClient
                                                            project={project}
                                                            clientId={clientId}
                                                            organisationName={organisationName}
                                                            clientName={clientName}
                                                            clientEmail={clientEmail}
                                                            setOrganisationName={setOrganisationName}
                                                            setClientName={setClientName}
                                                            setClientPhone={setClientPhone}
                                                            setClientEmail={setClientEmail}
                                                            setClientId={setClientId}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* <div className="col-lg-6">
                                                <div className="project-subheader">
                                                    <i className="fas fa-fire-alt" style={{paddingRight:'.5rem'}}/>
                                                    <label>Lote Project Manager</label>
                                                </div>
                                                <Row className="mt-3">
                                                    <div className="contact-row">
                                                        <span className="contact-label col-md-2">Name</span>
                                                        <span>{managerName}</span>
                                                    </div>
                                                    <div className="contact-row">
                                                        <span className="contact-label col-md-2">Phone</span>
                                                        <span>{managerPhone}</span>
                                                    </div>
                                                    <div className="contact-row">
                                                        <span className="contact-label col-md-2">Email</span>
                                                        <span>{managerEmail}</span>
                                                    </div>
                                                </Row>
                                            </div> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="flexbox-column" style={{textAlign:'right', marginRight: '1rem'}}>
                                    <label className="contact-label" style={{justifyContent:'right'}}>Last Updated</label>
                                    <span>{updatedAt.toString().substr(0,21)}</span>
                                </Col>
                                {/* <Col>
                                    <h5 className="mb-2">Action</h5>
                                    <div className="project-container">
                                        <div className="project-section">
                                            <div className="space-between col-md-12">
                                                <div>
                                                    <span className="section-label">Project Status</span>
                                                    <span style={{fontWeight: 600}}>{status}</span>
                                                </div>
                                                <div className="icon-flex">
                                                    <EditProjectPhase 
                                                        projectId={project.id}
                                                        history={history}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-container">
                                        <div className="project-section">
                                            <div className="space-between col-md-12">
                                                <div>
                                                    <span className="section-label">Customer Action</span>
                                                    <span style={{fontWeight: 600}}>{action}</span>
                                                </div>
                                                <div className="icon-flex">
                                                    <ClearCustomerAction
                                                        projectId={project.id}
                                                    />
                                                    <EditCustomerAction
                                                        projectId={project.id}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                        <div className="section-header mt-3">
                            <h5>Invoices</h5>
                            <AddInvoice 
                                projectId={projectId}
                                project={project}
                                clientId={clientId}
                            />
                        </div>
                        <div className="project-container">
                            <Table hover variant={cardMode}>
                                <thead>
                                    <tr>
                                        <th>Invoice</th>
                                        <th><i className="fas fa-percentage" style={{color: 'grey'}}/> Fee</th>
                                        <th><i className="fas fa-dollar-sign" style={{color: 'grey'}}/> Amount (ex. GST)</th>
                                        <th><i className="fas fa-file-invoice-dollar" style={{color: 'grey'}}/> Invoice</th>
                                        <th><i className="fas fa-calendar-day" style={{color: 'grey'}}/> Due Date</th>
                                        <th>File</th>
                                        <th>Paid</th>
                                        <th style={{textAlign: 'center'}}>       
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={invoicePaidTooltip}
                                            >
                                                <Button variant="secondary" size="sm">
                                                    <i className="fas fa-info custom-icon" style={{display: 'center', fontSize: '.5rem'}}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <InvoiceRow/>
                                </tbody>
                            </Table>
                        </div>
                        <div className="section-header mt-5">
                            <h5>Documents</h5>
                            <AddDocument
                                project={project}
                                projectId={projectId}
                                clientId={clientId}
                                isRevision={false}
                            />
                        </div>
                        <div className="project-container">
                            <div className="revision-section">
                                <Table hover variant={cardMode}>
                                    <thead>
                                        <tr>
                                        <th>Document Type</th>
                                        <th>Prepared By</th>
                                        <th>Reviewed By</th>
                                        <th>Authorised By</th>
                                        <th>Revision Date</th>
                                        <th>Revision</th>
                                        <th></th>
                                        <th style={{textAlign:'center'}}>File</th>
                                        <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <DocumentRow />
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="section-header mt-5">
                            <h5>Timesheets</h5>
                        </div>
                        <TimesheetDisp />
                        <div className="section-header mt-5">
                            <h5>Tasks</h5>
                        </div>
                        <div className="project-container">
                            <Table hover variant={cardMode}>
                                <thead>
                                    <tr>
                                        <th>Responsible</th>
                                        <th>Action</th>
                                        <th>Deadline</th>
                                        <th>Complete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TaskRow/>
                                </tbody>
                            </Table>
                        </div>
                        {/* <div className="space-between mt-5 mb-2">
                            <h5>Metadata (Internal Use)</h5>
                            <EditClassifications
                                project={project}
                                stage={stage}
                                classification={buildingClass}
                                value={projectValue}
                                loteFee={loteFee}
                                sector={sector}
                                service={service}
                                tags={tags}
                                nonCompliance={nonCompliance}
                                featured={featured}
                                over25mHeight={over25mHeight}
                                internalNote={internalNote}
                                googleDrive={driveLink}
                                googlePhotos={photosLink}
                                featureDescription={featureDescription}
                            />
                        </div>
                        <div className="project-container" style={{padding: '.75rem'}}>
                            <Row className="mb-2">
                                <Col md="4" className="mb-2">
                                    <span className="section-label">Stage</span>
                                    <span>{classificationFormatted(stage)}</span>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <span className="section-label">Class</span>
                                    <span>{classificationFormatted(buildingClass)}</span>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <span className="section-label mb-1">Services</span>
                                    <span>{classificationMultiFormatted(serviceBadges)}</span>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="mb-2">
                                <Col md="4" className="mb-2">
                                    <span className="section-label mb-1">Sector</span>
                                    <span>{classificationMultiFormatted(sectorBadges)}</span>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <span className="section-label mb-1">Search Tags</span>
                                    <span>{classificationMultiFormatted(tagBadges)}</span>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <span className="section-label mb-1">Non Compliance</span>
                                    <span>{classificationMultiFormatted(nonComplianceBadges)}</span>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="mb-2">
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Value</span>
                                    <span>{projectValueFormatted}</span>
                                </Col>
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Lote Fee</span>
                                    <span>{loteFeeFormatted}</span>
                                </Col>
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Building Height</span>
                                    <span>{heightFormatted}</span>
                                </Col>
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Google Drive Folder</span>
                                    <span>{driveLinkFormatted(driveLink)}</span>
                                </Col>
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Photos</span>
                                    <span>{photosLinkFormatted(photosLink)}</span>
                                </Col>
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Feature Project</span>
                                    <span>{featureFormatted}</span>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="mb-2">
                                <Col md="10" className="mb-2">
                                    <span className="section-label">Feature Project Description</span>
                                    <span>{featureDescriptionFormatted}</span>
                                </Col>
                                <Col md="2" className="mb-2">
                                    <span className="section-label">Feature Image</span>
                                    <DownloadImage
                                        file={featureImage}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="mb-2">
                                <Col className="mb-2">
                                    <span className="section-label">Internal Notes</span>
                                    <span>{internalNote}</span>
                                </Col>
                            </Row>
                        </div> */}
                    </div>
                </Container>
            </section>
        );
    }
}

function DownloadRevision(props) {
    if (!props.oversize && props.file) {
        return (
            <Button href={props.file.url()} target='_blank' rel="noreferrer"> <i className="fas fa-file"/></Button>
        )
    } else if (props.oversize && props.cloudLink) {
        return (
            <Button href={props.cloudLink} target='_blank' rel="noreferrer"> <i className="fas fa-file"/></Button>
        )
    } else {
        return null;
    }
}

// function DownloadImage(props) {
//     if (props.file) {
//         return (
//             <a href={props.file.url()} target="_blank" rel="noreferrer">
//                 Download
//             </a>
//         )
//     } else {
//         return (
//             <>N/A</>
//         )
//     }
// }
