import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';

export default function PageNotFound404() {

    useEffect(() => {
        document.title = "Portal – 404 error";
    }, []);

    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }
    return (
        <div>
            <h2>404 Page not found</h2>
            <Button onClick={goBack}>Go back</Button>
        </div>
    )
}
